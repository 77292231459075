<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
       hide-overlay
      temporary
    >
    <v-list nav >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >



 <v-list-item :to="{name: 'Services'}">
          <v-list-item-icon>
            <v-icon>mdi-emoticon-happy-outline</v-icon>
          </v-list-item-icon>
            <v-list-item-title>A votre service</v-list-item-title>
          </v-list-item>

        <v-list-item :to="{name: 'CV'}">
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
            <v-list-item-title>CV</v-list-item-title>
          </v-list-item>
        
          <v-list-item  :to="{name: 'Experiences'}">
              <v-list-item-icon>
            <v-icon>mdi-history</v-icon>
          </v-list-item-icon>
            <v-list-item-title>Expériences</v-list-item-title>
          </v-list-item>
         
            <v-list-item :to="{name: 'Contact'}">
               <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
              <v-list-item disabled>
               <v-list-item-icon>
            <v-icon>mdi-code-tags</v-icon>
          </v-list-item-icon>
           <v-badge
        bordered
        color="error"
        content="A venir"
      >
            <v-list-item-title>Projets</v-list-item-title>
           </v-badge>
          </v-list-item>
        </v-list-item-group>
      </v-list>

 </v-navigation-drawer>
  <div v-if="drawer" class="custom_overlay" @click="drawer = !drawer"></div>
    <v-app-bar
      app
      color="white"
      dark
    >
      <v-toolbar-title class="headline text-uppercase black--text" :to="{name: 'Home'}">
        <v-avatar size="36">
          <img
        src="img/simon.jpeg"
        alt="Simon"
      >
        </v-avatar>
      <span  class="font-weight-light">Simon</span>
      <span>BLUM</span>
    </v-toolbar-title>
          <v-spacer></v-spacer>

         <v-app-bar-nav-icon v-if="$vuetify.breakpoint.name == 'xs'" @click.stop="drawer = !drawer" color="black" ></v-app-bar-nav-icon>

<div v-else>
  
      <v-btn
      class="black--text"
       :to="{name: 'CV'}"
        text
      >
        <span class="mr-2">CV</span>
       
      </v-btn>
      
       <v-btn
      class="black--text"
        :to="{name: 'Experiences'}"
        text
      >
        <span class="mr-2">Expériences</span>
       
      </v-btn>
       <v-btn
      class="black--text"
        :to="{name: 'Contact'}"
        text
      >
        <span class="mr-2">Contact</span>
       
      </v-btn>
      </div>
    </v-app-bar>
 

    <v-main class="overflow-hidden">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },
watch: {
    group () {
      this.drawer = false
    },
  },
  data: () => ({
    drawer: false,
    group: null
    //
  }),
};
</script>
<style lang="scss">
#app {
font-family: 'Baloo 2', cursive;

}
.custom_overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(50,50,50,0.5);
    z-index:2;
}

</style>
