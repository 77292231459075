import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      return {name: 'Services'}
    }
  },{
    path: '/developpeur-fullstack-freelance',
    name: 'Services',
    component: () => import(
      /* webpackChunkName: "home" */
      /* webpackPreload: true */
       '@/components/services')
  },
  {
    path: '/cv',
    name: 'CV',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "cv" */
       /* webpackPreload: true */
        '@/components/cv')
  },
  {
    path: '/experiences',
    name: 'Experiences',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "experiences" */
      /* webpackPreload: true */
       '@/components/experiences')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "contact" */
      /* webpackPreload: true */
       '@/components/contact')
  },
  {
    path: '/chalamont',
    name: 'Chalamont',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
      /* webpackChunkName: "chalamont" */
      /* webpackPreload: true */
       '@/components/chalamont')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
